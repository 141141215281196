.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(
      650px circle at 0% 0%,
      #2b2b2b 15%,
      #2b2b2b 35%,
      #1c1924 75%,
      #1c1924 80%,
      transparent 100%
    ),
    radial-gradient(
      1250px circle at 100% 100%,
      #203a2d 15%,
      #2b2b2b 35%,
      #322149db 75%,
      #1c1924 80%,
      #1f362b 100%
    );
  min-height: 100vh;
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#b62e47, #e34038);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#b62e47, #e34038);
  overflow: hidden;
}

.bg-glass {
  background: #2b2b2b;
  color: #fff;
  padding: 2rem 3rem;
}
.bg-glass .form-group {
  margin-bottom: 0.75rem;
}
.Reset-password span {
  font-size: 16px;
  font-weight: 400;
  margin-top: 15px;
  display: block;
  color: #ccc;
  cursor: pointer;
}
.Reset-password span:hover {
  color: #fff;
}
.bg-glass h2 {
  font-size: 20px;
  text-align: center;
  margin-bottom: 2rem;
}
div#recapacha-style div {
  margin: 0 auto;
}
.bg-border-gradiant.contact-form-bg {
  background: unset;
}
.bg-border-gradiant {
  position: relative;
}
.bg-border-gradiant {
  box-sizing: border-box;
  border-radius: 15px;
  color: #fff;
}

.contact-form-bg .content-what-bullit h3 span {
  background: linear-gradient(270deg, #0ff 0, #48b8e4 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 20px;
}
.logo-admin {
  display: grid;
}
.logo-admin img {
  margin-right: auto;
  margin-left: auto;
}
.logo-admin .logo-admin-img {
  margin-bottom: 15px;
  height: 60px;
}
.logo-admin .logo-admin-text {
  height: 30px;
  margin-bottom: 2rem;
}
.contact-form-bg h5 {
  color: #9be7ff;
  margin-top: 14px;
  font-size: 14px;
  font-weight: 100;
}
.pl-10,
.px-10 {
  padding-left: 2.5rem !important;
}
.pr-10,
.px-10 {
  padding-right: 2.5rem !important;
}
.btn-group-lg > .btn,
.btn-lg {
  padding: 0.825rem 1.42rem;
  font-size: 1.08rem;
}
.bg-border-gradiant .form-control {
  background-color: #001770;
  border-color: #0b4a8f;
  color: #fff;
}
.bg-border-gradiant.contact-form-bg .form-control.form-control-solid {
  border: 1px solid #545454 !important;
  color: #fff;
  -webkit-transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  caret-color: #fff;
}
.bg-border-gradiant.contact-form-bg .form-control.form-control-solid {
  background-color: #081a3b00;
}
.form-control::-webkit-input-placeholder {
  color: #969696;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #969696;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #969696;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #969696;
  opacity: 1;
}
.form-control::placeholder {
  color: #969696;
  opacity: 1;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 14px;
}
textarea.form-control {
  height: auto;
}
.contact-form-bg .btn.btn-primary {
  background: linear-gradient(
    96.27deg,
    #115743 12.79%,
    #4eb093 50.13%,
    #115743 107.19%
  );
  border-radius: 8px;
  color: #fff;
  border: 0px;
  font-size: 16px;
  font-weight: 400;
}
.contact-form-bg .btn.btn-primary:hover,
.contact-form-bg .btn.btn-primary:active {
  background: #d95c50;
}
.contact-form-bg .btn.btn-secondary {
  background: #8200ff00;
  border-radius: 8px;
  color: #fff;
  border: 1px solid #ffffff57;
  font-size: 16px;
  font-weight: 400;
  margin-left: 7px;
}
.contact-form-bg .btn.btn-secondary:hover,
.contact-form-bg .btn.btn-secondary:active {
  background: #2c2c2c;
}
.bg-border-gradiant.contact-form-bg input:-webkit-autofill,
.bg-border-gradiant.contact-form-bg input:-webkit-autofill:focus,
.bg-border-gradiant.contact-form-bg input:-webkit-autofill:hover,
.bg-border-gradiant.contact-form-bg select:-webkit-autofill,
.bg-border-gradiant.contact-form-bg select:-webkit-autofill:focus,
.bg-border-gradiant.contact-form-bg select:-webkit-autofill:hover,
.bg-border-gradiant.contact-form-bg textarea:-webkit-autofill,
.bg-border-gradiant.contact-form-bg textarea:-webkit-autofill:focus,
.bg-border-gradiant.contact-form-bg textarea:-webkit-autofill:hover {
  border: 1px solid #545454;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0 1000px #3b3b3b inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
.invalid-feedback {
  text-transform: capitalize;
}
iframe[title="reCAPTCHA"] .rc-anchor-normal {
  width: 100% !important;
}
.rc-anchor-light.rc-anchor-normal {
  margin-left: auto;
  margin-right: auto;
}
.admin .card-title {
  font-weight: 400;
  font-size: 1.125rem;
  color: #181c32;
  line-height: unset;
}
@media (max-width: 767.98px) {
  .contact-form-bg .btn.btn-secondary {
    margin-left: 0;
    border-radius: 30px;
    width: 100%;
    margin-top: 5px;
  }
  .bg-glass {
    padding: 2rem 1rem;
  }
  div#recapacha-style {
    margin-left: -15px;
  }
  .min-height-100per.Team.GetTouch {
    margin-top: -8rem;
  }
  .rights p {
    text-align: center;
    color: #a3a3a3;
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    padding-top: 20px;
  }
  .contact-form-bg .btn.btn-primary {
    border-radius: 30px;
    width: 100%;
    margin-top: 0px;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  .Team.GetTouch h1 {
    font-size: 38px;
    line-height: 40px;
    margin-bottom: 2rem;
  }
  iframe[title="reCAPTCHA"] {
    margin-left: -30px;
  }
  body > iframe > body > .rc-anchor-light.rc-anchor-normal {
    border: 1px solid #d3d3d3;
    width: 100% !important;
  }
  body .rc-anchor-light.rc-anchor-normal {
    margin: 0 auto !important;
  }
  .rc-anchor-normal .rc-anchor-pt {
    margin: 2px 0 0 0 !important;
    left: 0;
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
}
.form-recaptcha .invalid-feedback {
  text-align: center;
}
.invalid-feedback {
  text-align: left;
  font-size: 12px;
}
.bg-glass .vi__wrapper {
  margin: 0 auto;
}
.bg-glass .vi__character--inactive {
  color: dimgray;
  background-color: #6a6a6a2e;
}
.bg-glass .vi__character {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  font-size: 36px;
  line-height: 50px;
  color: white;
  background-color: #3b3b3b;
  border-radius: 6px 6px 0px 0;
  border: 0px solid white;
  border-bottom: 1px solid #fff;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
}
