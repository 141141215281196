.table-container {
  padding: 30px 0 10px;
}

.purchasing-table {
  width: 100%;
  column-gap: 21px;
  row-gap: 21px;
  border-spacing: 0 1rem;
  border-color: #ced3df;
}

.purchasing-table > :not(caption) > * > * {
  background-color: #fff0;
  border-bottom-width: 1px;
  border-color: rgba(119, 126, 144, 0.2);
  vertical-align: middle;
}

.purchasing-table th {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff9e;
  background-color: #3b3b3b;
}
.purchasing-table th:last-child {
  padding-right: 2rem;
}
.purchasing-table td {
  text-align: left !important;
  vertical-align: center !important;
  background-color: #3b3b3b;
}

.purchasing-table td:nth-child(n + 1) {
  font-weight: 600;
  font-size: 14px;
  color: #fcfcfd;
}

.purchasing-table td:nth-child(n + 2) {
  font-weight: 400;
  font-size: 14px;
  line-height: 38px;
  color: #a3a3a3;
}

.purchasing-table tr:last-child {
  border-bottom: none;
}

.purchase-state {
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  border-radius: 25px;
  display: inline-block;
}
.disablePagination {
  opacity: 0.2;
}
.pointerPagination {
  cursor: pointer;
}
.complete-purchase {
  color: #50c36f;
  background: rgba(80, 195, 111, 0.15);
}

.incomplete-purchase {
  color: #ff5b5b;
  background: rgba(255, 91, 91, 0.15);
}

.claimed-purchase {
  color: #ff4a41;
  background: rgba(159, 68, 247, 0.15);
}
.CommissionsDetails {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.borderSpace {
  position: absolute;
  width: 100%;
  height: 1px;
  background: #ff9800;
  background: linear-gradient(50deg, rgb(248 120 12 / 0%) 0%, #ff9800 100%);
  top: 38px;
}
.CommissionsDetails h2.table-label {
  background: #2b2b2b;
  z-index: 1;
  padding-right: 15px;
}
.table-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #fcfcfd;
  margin-bottom: 20px;
  margin-top: 25px;
}
h2.table-label.totalCommission {
  color: #ff9800;
  padding-left: 7px;
}
.purchase-table-pagenation {
  font-family: "Sora" !important;
  font-size: 10px !important;
  line-height: 18px !important;
  color: #ced3df !important;
}

.purchasing-table:last-child {
  border-style: hidden !important;
}

.table-no-data {
  line-height: 100px;
  text-align: center;
  color: #a3a3a3;
  margin-top: 30px;
}
td.table-no-data {
  text-align: center !important;
}
