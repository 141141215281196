.bg-live {
  background: #3b3b3b;
  border-radius: 20px;
  text-align: center;
}

.shared-margin {
  margin: 0;
}

.live-presale-max-width {
  /* max-width: 1424px; */
  margin: 0 auto;
}

/* rocket.js  */

.live-now-section {
  padding: 40px 0;
  border-radius: 20px;
  background: url(../../../../assets/bg3.png);
  background-repeat: no-repeat;
  min-height: 55.5%;
  max-height: 62%;
  height: 100%;
  margin-bottom: 1.5rem;
  background-size: 129%;
  background-position: 2% 8%;
  position: relative;
}
.live-presale-now {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.live-presale-now-text h3 {
  font-weight: 400;
  font-size: 28px;
  color: #fff;
}

.live-presale-now-text h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 54px;
  letter-spacing: 0.32em;
  text-transform: uppercase;
  background: linear-gradient(96.27deg, #fff 13.79%, #fff 37.13%, #fff 87.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.live-timers {
}

.live-timers .count {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(78, 78, 97, 0.05);
  backdrop-filter: blur(4.5px);
  border-radius: 6px;
  border: 2px solid #eeeeee1c;
}

.live-timers .count h1 {
  margin-top: 2rem;
  font-size: 32px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.143359px;
  color: #ffffff;
}

.live-timers .count p {
  font-weight: 300;
  font-size: 8.98981px;
  line-height: 36px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.184593px;
  text-transform: uppercase;

  color: #fff;
}

/* buy.js  */

.live-buy-section {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 2rem 0 30px;
}

.live-buy-section h1 {
  font-weight: 600;
  font-size: 32px;
  line-height: 125%;
  text-transform: uppercase;
  color: #fcfcfd;
}

.bnb-address p {
  font-weight: 500;
  font-size: 16px;
  color: #fcfcfd;
  margin: 0 !important;
  display: grid;
  text-align: left;
}
svg.cursor-pointer {
  fill: #f8780c;
}
span.balanceLable {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff9e;
}
.presal-amount div span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
  /* background: #f8780c; */
  background: #f8780c;
  border-radius: 114px;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
}

.presal-amount div h2 {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #fcfcfd;
}

.presal-amount h6 {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 0;
}

.presal-amount input {
  background: transparent;
  border: none;
  outline: none;
  color: #fcfcfd;
  font-size: 16px;
  line-height: 24px;
  width: 150px;
}

.fees-data {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.fees-data div {
  display: flex;
  justify-content: space-between;
}

.fees-data h6 {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff9e;
}

.fees-data h2 {
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  color: #fcfcfd;
}
button.totalPriceH2 {
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  color: #fcfcfd;
  padding: 0;
  padding-right: 10px;
}
.purchasing-table th button.totalPriceH2 {
  padding-right: 0;
  font-size: 14px;
  color: inherit;
  cursor: default;
}
.outlinedWarn {
  color: #ff9800 !important;
}
.outlinedWarn .MuiAlert-icon {
  display: none;
}

.outlinedWarn2 {
  color: #ff9800 !important;
}
.outlinedWarn3 {
  color: #ff9800 !important;
}
.outlinedWarn2 .MuiAlert-icon {
}

button.AlertH2 {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  color: #ff9800 !important;
  padding: 0px 3px 0px 5px;
}
.bnbAlertH2 button.AlertH2 {
  margin-top: -2px;
}
.outlinedWarn svg {
  color: #ff9800 !important;
  display: none !important;
}
.bnbAlertH3 button.AlertH2 {
  margin-top: -2px;
}
.fees-data div.bnbAlertH3 {
  justify-content: unset;
  text-align: left;
}
.outlinedWarn2 svg {
  color: #ff9800 !important;
  width: 30px;
  height: auto;
}
.outlinedWarn3 svg {
  color: #ff9800 !important;
  width: 1em;
  height: auto;
}
.presale-submit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 12px;
  /* background: #f8780c; */
  background: linear-gradient(96.27deg, #4eb093 12.79%, #115743 50.13%, #4eb093 107.19%);
  border-radius: 57px;
  flex: none;
  order: 4;
  flex-grow: 0;

  font-size: 16px;
  color: #fcfcfd;
  width: 100%;

  cursor: pointer;
}

.presale-submit-btn:hover {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  opacity: 0.8;
}
span.presale-submit-btn.disabled {
  opacity: 0.4;
  cursor: unset;
  background: linear-gradient(96.27deg, #4eb093 12.79%, #115743 50.13%, #4eb093 107.19%);
}
.presale-submit-btn.disabled:hover {
  background: linear-gradient(96.27deg, #4eb093 12.79%, #115743 50.13%, #4eb093 107.19%);
}
.presale-submit-btn:disabled {
  background: linear-gradient(96.27deg, #115743 12.79%, #4eb093 50.13%, #115743 107.19%);
  opacity: 0.5;
  cursor: not-allowed;
}

.bnb-address div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 14px;
  border: 1px solid #777e90;
  border-radius: 136px;
  font-size: 14px;
  color: #ced3df;
  height: 42.33px;
  width: 163px;
}
.bnb-address-sponsor-content button.totalPriceH2 {
  padding-right: 0px;
}
.bnb-address-sponsor .bnb-address-sponsor-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 2px 2px 10px;
  border: 1px solid #777e90;
  border-radius: 136px;
  font-size: 14px;
  color: #ced3df;
  height: 42.33px;
  width: 163px;
}
img.avatarRespons {
  width: 37px;
  height: 37px;
  object-fit: cover;
  border-radius: 50%;
}
/* share.js  */

.copy-share-affiliate:hover {
  cursor: pointer;
}

.first-row-in-share {
  /* display: flex; */
  /* column-gap: 21px; */
  margin-bottom: 1rem;
}

.first-row-in-share div {
  /* display: flex;
  row-gap: 8px; */
  text-align: left;
  /* align-items: center !important; */
}

.first-row-in-share div h2 {
  font-weight: 600;
  font-size: 28px;
  align-items: center;
  color: #ffffff;
  margin-bottom: 0;
  margin-right: 15px;
  width: 108%;
}

.first-row-in-share div p {
  color: #ffffff;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 0;
  letter-spacing: 2px;
}
.live-buy-section.live-buy-sectionCard {
  padding: 2rem 0;
}
.purple-span {
  color: #f8780c;
}

.second-row-in-share {
  display: flex;
  column-gap: 21px;
  align-items: center;
}

.share-link {
  padding: auto;
  width: 85%;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff9e;
  border: 1px solid #777e90;
  border-radius: 8px;
  margin: 0;
}
.smShowModal .modal-body canvas {
  width: 100% !important;
  height: auto !important;
}
.share-btns svg.cursor-pointer {
  width: 25px;
  height: auto;
}
.max-telv {
  display: inline;
}
.min-telv {
  /* display: none; */
}

.share-link p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 7px;
}

.share-btns {
  display: flex;
  column-gap: 15px;
}
svg.BsQrCodeScan {
  width: 22px;
  height: auto;
  fill: #f8780c;
  cursor: pointer;
}
/* purchase.js */

.purchasing-table {
  width: 100%;
  column-gap: 21px;
  row-gap: 21px;
  border-spacing: 0 1rem;
  border-color: #ced3df;
}

.purchasing-table > :not(caption) > * > * {
  border-bottom-width: 1px;
  border-color: rgba(119, 126, 144, 0.2);
  vertical-align: middle;
}
.table-container {
  padding: 30px 0 10px;
}

.purchasing-table th {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff9e;
}

.purchasing-table td {
  text-align: left !important;
  vertical-align: center !important;
}

.purchasing-table td:nth-child(n + 1) {
  font-weight: 600;
  font-size: 14px;
  color: #fcfcfd;
}

.purchasing-table td:nth-child(n + 2) {
  font-weight: 400;
  font-size: 16px;
  line-height: 38px;
  color: #a3a3a3;
}

.purchasing-table tr:last-child {
  border-bottom: none;
}
.purchasing-table th button.totalPriceH2.pr-3 {
  padding-right: 5px;
}
.purchase-state {
  font-weight: 500;
  font-size: 12px;
  line-height: 10px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  gap: 8px;
  border-radius: 25px;
  display: inline-block;
}

.complete-purchase {
  color: #50c36f;
  background: rgba(80, 195, 111, 0.15);
}

.incomplete-purchase {
  color: #ff5b5b;
  background: rgba(255, 91, 91, 0.15);
}

.table-label {
  font-weight: 400;
  font-size: 16px;
  color: #fcfcfd;
  margin-bottom: 18px;
  margin-top: 16px;
}

.purchase-table-pagenation {
  font-family: "Sora" !important;
  font-size: 10px !important;
  line-height: 18px !important;
  color: #ced3df !important;
}

.purchasing-table:last-child {
  border-style: hidden !important;
}

.table-no-data {
  line-height: 100px;
  text-align: center;
  color: #a3a3a3;
  margin-top: 30px;
}

/* pagination.js */
.pagination {
  font-size: 14px;
  margin-top: 20px;
  color: #ced3df;
}
.pagination ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 18px;
  list-style: none;
  margin: 0 20px;
}

.active-page {
  font-weight: bold;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
}
.pagination ul li.page {
  cursor: pointer;
  opacity: 0.7;
}
@media (max-width: 1235px) {
  .live-share-section {
    zoom: 0.95;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 1150px) {
  .live-share-section {
    zoom: 0.9;
  }
}

@media (max-width: 1090px) {
  .live-share-section {
    zoom: 0.85;
  }
}

@media (max-width: 1030px) {
  .live-share-section {
    zoom: 0.8;
  }
}

@media (max-width: 991px) {
  .live-now-section {
    background-size: 150%;
  }
  .live-timers .count h1 {
    font-size: 25px;
  }
  .live-share-section {
    margin-bottom: 1rem;
    zoom: 1;
  }
  .live-now-section {
    min-height: 40vw;
    height: auto;
  }
  .shared-space {
    margin-bottom: 10px;
  }
}

.minAllo-span {
  font-size: 14px;
  color: #ff9800;
  background: #2b2b2b91;
  padding: 0px 5px;
  border-radius: 4px;
  letter-spacing: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

@media (max-width: 768px) {
  button.totalPriceH2 {
    font-size: 14px;
    padding-right: 5px;
  }
  span.logoAndTitle.navbar-brand span {
    margin-top: 2px;
    font-size: 12px;
  }
  .rights p {
    padding-bottom: 20px;
  }
  .textVedio {
    margin-bottom: 20px;
  }
  body {
    height: unset;
  }
  .navbar-brand {
    line-height: 1;
  }
  img.logo-mobile {
    width: 65px;
  }
  .first-row-in-share .col-md-4 p {
    margin-bottom: 25px;
  }
  .live-timers {
    zoom: 0.85;
    padding: 0 5px;
  }
  .max-telv {
    display: none;
  }
  .min-telv {
    display: inline;
  }
  .first-row-in-share {
    flex-direction: column;
  }
  .first-row-in-share div h2 {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 40px;
    width: 100%;
    white-space: nowrap;
  }
  .first-row-in-share div p {
    font-size: 20px;
    display: flex;
    flex-flow: column-reverse;
  }
  .second-row-in-share {
    flex-direction: row;
  }
  .pagination {
    margin-top: 12px;
  }

  .share-link {
    width: 73%;
  }
  .share-btns {
    width: 100%;
    margin-top: 0rem;
    justify-content: center;
  }
  .share-btns img {
    width: 36px;
    margin-bottom: 1rem;
  }
  .live-buy-section {
    zoom: calc(100vw / 768);
  }
}

@media (max-width: 600px) {
  .live-buy-section {
  }
}
@media (max-width: 575.98px) {
  .live-presale-now {
    justify-content: flex-end;
    row-gap: 15%;
  }
  .live-presale-now-text h3 {
    font-size: 20px;
  }
  .live-presale-now-text h3 span {
    display: none;
  }
  .live-presale-now-text h1 {
    font-size: 28px;
    letter-spacing: 0.03em;
  }
  .bnb-address {
    flex-direction: column;
    align-items: flex-start !important;
    row-gap: 14px;
  }
  .bnb-address div {
    row-gap: 5px;
    width: -webkit-fill-available;
    justify-content: space-between;
  }
}

@media (max-width: 450px) {
  .first-row-in-share {
    zoom: 0.75;
  }
}

.btn-orange {
  background-color: #f8780c;
  color: white;
  border: none;
}

.btn-orange:hover {
  background-color: #e46d0b;
  color: white;
}

.btn-orange:focus {
  background-color: #d0620a; ;
  color: white;
  box-shadow: none;
}

.btn-orange:active, .btn-orange:focus-visible {
  background-color: #d0620a !important; ;
  color: white;
  box-shadow: none;
}

.btn-orange:disabled {
  background-color: rgba(248, 120, 12, 0.8);
  color: white;
  cursor: not-allowed;
}

.custom-radio {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
  margin-right: 10px;
  transition: border-color 0.2s ease-in-out;
}

.custom-radio:checked {
  border-color: #f8780c;
}

.custom-radio:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  background-color: #f8780c;
  border-radius: 50%;
  transform: scale(0) translate(-50%, -50%);
  transition: transform 0.2s ease-in-out;
}

.custom-radio:checked:before {
  transform: scale(1) translate(-50%, -50%);
}

.custom-radio:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(248, 120, 12, 0.25);
}
